<template>
  <div class="make_over_page">
    <div class="page_top">
      <div class="title">账号设置</div>
      <div></div>
    </div>
    <el-form
      ref="makeOverFrom"
      :rules="rules"
      style="padding: 0.2rem"
      :model="form"
      label-width="2rem"
    >
      <el-form-item class="form_item_block" prop="username" label="联系人姓名">
        <el-input v-model="form.username" disabled></el-input>
      </el-form-item>
      <el-form-item class="form_item_block" prop="mobile" label="手机号">
        <el-input v-model="form.mobile" disabled></el-input>
      </el-form-item>
      <el-form-item class="form_item_block" prop="oldPassword" label="旧密码">
        <el-input type="password" v-model="form.oldPassword"></el-input>
      </el-form-item>
      <el-form-item class="form_item_block" prop="password" label="新密码">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item
        class="form_item_block"
        prop="newPassword"
        label="再次确认密码"
      >
        <el-input type="password" v-model="form.newPassword"></el-input>
      </el-form-item>
      <el-form-item class="form_item_block btns" label="">
        <el-button type="primary" class="submit" @click="submit"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "makeOver",
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if(!this.common.validateLength(value,16)){
          callback(new Error("请输入正确格式密码"));
        }else{
          if (this.form.newPassword !== "") {
            this.$refs.makeOverFrom.validateField("newPassword");
          }
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (!this.common.isMobile(value)) {
        callback(new Error("请输入正确的手机格式"));
      } else {
        callback();
      }
    };
    return {
      form: {
        username: this.$store.getters.getSchoolInfo.atteSchool.contacts,
        password: "",
        newPassword: "",
        mobile: this.$store.getters.getSchoolInfo.atteSchool.contactsMobile,
      },
      rules: {
        username: [
          { required: true, message: "请输入新用户名", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        newPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        mobile: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    schoolInfo() {
      return this.$store.getters.getSchoolInfo.atteSchool;
    },
  },
  created() {
    console.log(this.schoolInfo)
    if(this.$store.state.userInfo.teacherId){
      this.form.username = this.$store.state.teacherInfo.name;
      this.form.mobile = this.$store.state.teacherInfo.phone;
    }
  },
  mounted() {},
  methods: {
    // 获取用户信息

    // 提交
    submit() {
      if(this.form.password === this.form.oldPassword){
        this.$notice({
          message: '您输入的密码已存在',
        }).isShow();
        return;
      }
      this.$refs.makeOverFrom.validate((valid) => {
        if (valid) {
          let data = {
            pwd: this.form.oldPassword,
            newPwd: this.form.password,
          };
          this.api.user.updatePassword(data).then((res) => {
            console.log(res);
            if (res.flag) {
              localStorage.removeItem("userInfo");
              this.$store.commit("setUserInfo", {});
              this.$store.commit("setLoginState", "");
              let num = 3;
              let timer = setInterval(() => {
                console.log(num);
                if (num == 1) {
                  clearInterval(timer);
                  let closeBtn =
                    document.body.getElementsByClassName("v-modal")[0];
                  closeBtn.click();
                  this.$router.push({
                    path: "/home",
                  });
                } else {
                  num = num - 1;
                }
              }, 1000);
              this.$confirm(res.message + `页面将在${num}s后进行跳转`, "提示", {
                confirmButtonText: "立即跳转",
                showCancelButton: false,
                type: res.flag ? "success" : "error",
              })
                .then(() => {
                  this.$router.push({
                    path: "/home",
                  });
                  this.$store.commit("setLoginState", "login");
                })
                .catch(() => {
                  this.$router.push({
                    path: "/home",
                  });
                  clearInterval(timer);
                  this.$store.commit("setLoginState", "login");
                });
            }else {
              this.$notice({
                    message: res.message,
                  }).isShow();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.make_over_page {
  .page_top {
    @include flex(row, space-between, center);
    margin-bottom: 50px;
    border-bottom: 10px solid #cc1a30;

    .title {
      font-size: 34px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #cc1a30;
      margin-bottom: 30px;
    }
  }
}
</style>
<style lang="scss">
.make_over_page {
  .el-form-item__label {
    font-size: 25px;
    line-height: 70px;
    padding-right: 20px;
  }

  .el-input__inner {
    height: 70px;
    font-size: 25px;
    border-width: 1px; /*no*/
    border-color: #857c7a;
    color: #000;
  }

  .form_item_block {
    margin-bottom: 40px !important;
  }

  .el-form-item__content {
    @include flex();
  }

  .code {
    width: 10%;
    height: 70px;
    margin-left: 2%;
    border-width: 3px;
    font-size: 25px;
    border-color: #857c7a;
    box-sizing: border-box;
    text-align: center;
    line-height: 70px;
    padding: 0;
  }

  .btns {
    @include flex(row, flex-end, center);
  }

  .submit {
    width: 100px;
    font-size: 0.25rem;
    line-height: 0.7rem;
  }
}
</style>